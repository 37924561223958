<template>
    <div class="zhishi" id="app" style="background-color:#F0F0F0;min-height: 1000px;overflow: hidden" >
        <div  style="max-width: 100%;height: 400px;margin-bottom: 10px;margin-top: 110px;background-image:url(https://baigongbao.oss-cn-beijing.aliyuncs.com/img/knowledge.jpg)" >
            <div style="width: 1440px;height: 300px;margin: 10px auto;">
                <div style="display: flex;justify-content: center">
                    <div style="height: 200px;width: 100%;margin: 0 6%;line-height: 270px;font-weight: bold;font-size: 40px;color: white;position: relative;text-align: center">
                        百工宝·知识库 —— 100万+行业资料
                    </div>
                </div>
                <div style="margin-top: 50px">
                    <div style="height: 56px;width: 1500px;display: flex;justify-content: center">
                            <div>
                                <div style="width: 100%;display: flex;color: white;text-align: center;">
                                    <div v-for="(label,index) in stash_type" :key="index" style="">
                                        <div style="width:90px;height: 35px;display: flex;justify-content: center;align-items:center;font-size: 16px;cursor: pointer;" @click="add_type(label.typeId)" :class="{ Fcti: label.typeId === low }">{{label.name}}</div>
                                    </div>
                                </div>
                                <div style="height: 56px;width: 846px;position: relative;background-color:#c2c2c266;border-radius: 5px;border-top-left-radius: 0px;">
                                    <el-input v-model="like" prefix-icon="el-icon-search" type="" name="" placeholder="请输入你要查找的资料......" style="height: 40px;width: 744px;border: none;float: left;margin-top: 8px;font-size: 16px;margin-left: 12px;"></el-input>
                                    <div style="padding: 8px 6px;float: left"><el-button type="primary" @click="searchProfile">查找</el-button></div>
                                </div>
                            </div>
                            <div style="height: 50px;padding-left: 20px;margin-top: 45px;padding-right: 10px">
                                <el-button class="el-icon-upload2" type="warning" @click="handleClick(0)">上传资料</el-button>
                            </div>
    <!--                        <div style="height: 50px;margin: 10px;margin-top: 45px;">-->
    <!--                            <el-button class="el-icon-download" type="primary">已下资料</el-button>-->
    <!--                        </div>-->
                            <div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 1500px;height:auto; margin: 0px auto 20px auto;overflow: hidden;">
            <div style="float:left;height:523px;width: 1500px;background-color: #ffffff; box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.1600);border-radius: 5px 5px 5px 5px;opacity: 1;">
                <div class="icon_list"></div>
                <div style="width:calc(100% - 20px);">
                    <div v-for="(item,index) in type_list" :key="item.value"  style="padding: 10px;width: 95%;margin: -10px 25px;border-bottom-style: solid;border-bottom-color: rgb(206, 207, 208);" :style="{ 'border-bottom-width': index != type_list.length - 1  ? '1px': '0px'}">
                        <!-- <div v-for="item in type_list" :key="item.value" style="padding: 10px;width: 95%;margin: -10px 25px;border-bottom: 1px solid #CECFD0;"> -->
                        <div style="display: flex;margin-top: 25px;">
                            <div style="flex:0 0 auto;width:80px;font-size: 20px;color: rgb(16, 16, 16);font-style: normal;letter-spacing: 0px;line-height: 25px;text-decoration: none;margin-bottom: 5px;font-weight:bold;margin-left: 15px;">{{item.label}}</div>
                            <div style="width: 1px;border-right: 1px solid #CECFD0;flex-direction: row;margin-left: 10px"></div>
                            <div style="overflow:hidden;">
                                <div class="hand op sc" v-for="(item2,index2)  in item.children" :key="index2" style="float:left;font-weight: 400;font-size: 18px;color: rgba(0, 0, 0, 0.63);font-style: normal;letter-spacing: 0px;line-height: 25px;text-decoration: none;padding-right: 10px;margin: 0 8px 5px 0;" >
                                    <el-dropdown @command="handleCommand">
                                        <div  class="el-dropdown-link" @click="look_type(item2.value)" style="margin-left: 15px;font-size: 16px;flex-wrap: nowrap;cursor: pointer;">
                                            {{item2.label}}<i class="el-icon-caret-bottom"></i>
                                        </div>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item v-for="(item3,index) in item2.children" :key="index" :command=String(item3.value)>{{ item3.label}}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 最新标准规范  -->
            <div style="float:left;height: 925px;width:calc((100% - 20px)/4);background-color: #ffffff;margin-right: 6px;margin-top: 15px;">
                <div style="width:100%;border-bottom: 1px solid #E4E4E4;overflow: hidden;height:75px;">
                    <div class="icon_list"></div>
                    <div style="float: left;font-size: 21px;color: rgb(16, 16, 16);letter-spacing: 0.4px;padding: 0 20px;width: calc(70% - 40px);display: flex;margin-left: 5px;">最新标准规范</div>
                    <div style="float:right;width: calc(20% - 10px);margin-right: 10px;text-align: right;font-size: 16px;cursor:pointer;color: #F98B15;" @click="addMore(1)">More</div>
                </div>
                <div style="width:calc(100% - 20px);padding: 10px;margin-left: 10px">
                    <div class="hand op sc" style="overflow:hidden;width: 100%;margin-bottom:9px;"  v-for=" item in standard_list.slice(0, 13)" :key="item.id" @click="content_info(item.id)">
                        <div v-if="item.payAmt > 0" style="float: left;width: 20px;height: 18px;padding: 8px 3px;">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/pay.png" style="width:100%;height: auto;">
                        </div>
                        <div v-else style="float: left;width: 22px;height: 22px;padding: 6px 3px;">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/other/word.png" style="width:100%;height: auto;">
                        </div>
                        <div style="float: left;width:calc(100% - 28px);">
                            <div style="overflow:hidden;width: 100%;">
                                <div style="float: left;width: 90%;line-height: 35px;letter-spacing:2px;white-space: nowrap;text-overflow: ellipsis;overflow:hidden;font-size: 16px;cursor: pointer;">{{item.title}}</div>
                                <div style="color: #999999;clear: both;font-size: 15px;">{{item.kwTypeArrName.join('> ')}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 最新政策法规  -->
            <div style="float:left;height: 925px;width:calc((100% - 20px)/4);background-color: #ffffff;margin-top: 15px;margin-right: 6px;">
                <div style="width:100%;border-bottom: 1px solid #E4E4E4;overflow: hidden; height:75px;">
                    <div class="icon_list"></div>
                    <div style="float: left;font-size: 21px;color: rgb(16, 16, 16);letter-spacing: 0.4px;padding: 0 20px;width: calc(70% - 40px);display: flex;margin-left: 5px;">最新政策法规</div>
                    <div style="float:right;width: calc(20% - 10px);margin-right: 10px;text-align: right;font-size: 16px;cursor:pointer;color: #F98B15;" @click="addMore(2)">More</div>
                </div>
                <div style="width:calc(100% - 20px);padding: 10px;margin-left: 10px">
                    <div class="hand op sc" style="overflow:hidden;width: 100%;margin-bottom:9px;"  v-for=" item in rule_list.slice(0, 13)" :key="item.id" @click="content_info(item.id)">
                        <div v-if="item.payAmt > 0" style="float: left;width: 20px;height: 18px;padding: 8px 3px;">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/pay.png" style="width:100%;height: auto;">
                        </div>
                        <div v-else style="float: left;width: 22px;height: 22px;padding: 6px 3px;">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/other/word.png" style="width:100%;height: auto;">
                        </div>
                        <div style="float: left;width:calc(100% - 28px);">
                            <div style="overflow:hidden;width: 100%;">
                                <div style="float: left;width: 90%;line-height: 35px;letter-spacing:2px;white-space: nowrap;text-overflow: ellipsis;overflow:hidden;font-size: 16px;cursor: pointer;">{{item.title}}</div>
                                <div style="color: #999999;clear: both;font-size: 15px;">{{item.kwTypeArrName.join('> ')}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 最新项目案例  -->
            <div style="float:left;height: 925px;width:calc((100% - 20px)/4);background-color: #ffffff;margin-top: 15px;margin-right: 6px;">
                <div style="width:100%;border-bottom: 1px solid #E4E4E4;overflow: hidden; height:75px;">
                    <div class="icon_list"></div>
                    <div style="float: left;font-size: 21px;color: rgb(16, 16, 16);letter-spacing: 0.4px;padding: 0 20px;width: calc(70% - 40px);display: flex;margin-left: 5px;">最新项目案例</div>
                    <div style="float:right;width: calc(20% - 10px);margin-right: 10px;text-align: right;font-size: 16px;cursor:pointer;color: #F98B15;" @click="addMore(3)">More</div>
                </div>
                <div style="width:calc(100% - 20px);padding: 10px;margin-left: 10px">
                    <div class="hand op sc" style="overflow:hidden;width: 100%;margin-bottom:9px;"  v-for=" item in case_list.slice(0, 13) " :key="item.id" @click="content_info(item.id)">
                        <div v-if="item.payAmt > 0" style="float: left;width: 20px;height: 18px;padding: 8px 3px;">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/pay.png" style="width:100%;height: auto;">
                        </div>
                        <div v-else style="float: left;width: 22px;height: 22px;padding: 6px 3px;">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/other/word.png" style="width:100%;height: auto;">
                        </div>
                        <div style="float: left;width:calc(100% - 28px);">
                            <div style="overflow:hidden;width: 100%;">
                                <div style="float: left;width: 90%;line-height: 35px;letter-spacing:2px;white-space: nowrap;text-overflow: ellipsis;overflow:hidden;font-size: 16px;cursor: pointer;">{{item.title}}</div>
                                <div style="color: #999999;clear: both;font-size: 15px;">{{item.kwTypeArrName.join('> ')}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 最新文章专著  -->
            <div style="float:left;height: 925px;width:calc((100% - 20px)/4);background-color: #ffffff;margin-top: 10px;margin-top: 15px;">
                <div style="width:100%;border-bottom: 1px solid #E4E4E4;overflow: hidden; height:75px;">
                    <div class="icon_list"></div>
                    <div style="float: left;font-size: 21px;color: rgb(16, 16, 16);letter-spacing: 0.4px;padding: 0 20px;width: calc(70% - 40px);display: flex;margin-left: 5px;">最新文章专著</div>
                    <div  style="float:right;width: calc(20% - 10px);margin-right: 10px;text-align: right;font-size: 16px;cursor:pointer;color: #F98B15;" @click="addMore(4)">More</div>
                </div>
                <div style="width:calc(100% - 20px);padding: 10px;margin-left: 10px">
                    <div class="hand op sc" style="overflow:hidden;width: 100%;margin-bottom:9px;"  v-for=" item in content_list.slice(0, 13)" :key="item.id" @click="content_info(item.id)">
                        <div v-if="item.payAmt > 0" style="float: left;width: 20px;height: 18px;padding: 8px 3px;">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/pay.png" style="width:100%;height: auto;">
                        </div>
                        <div v-else style="float: left;width: 22px;height: 22px;padding: 6px 3px;">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/other/word.png" style="width:100%;height: auto;">
                        </div>
                        <div style="float: left;width:calc(100% - 28px);">
                            <div style="overflow:hidden;width: 100%;">
                                <div style="float: left;width: 90%;line-height: 35px;letter-spacing:2px;white-space: nowrap;text-overflow: ellipsis;overflow:hidden;font-size: 16px;cursor: pointer;">{{item.title}}</div>
                                <div style="color: #999999;clear: both;font-size: 15px;">{{item.kwTypeArrName.join('> ')}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="overflow:hidden;width: 1500px;height:auto; margin: -5px auto;background-color: #FFFFFF">
            <div style="width:100%;border-bottom: 1px solid #E4E4E4;overflow: hidden; height:75px;line-height:75px;">
              <div class="icon_list" style="float: left"></div>
              <div style=" width: calc(25% - 50px);font-size: 20px;color: #333333;font-weight:bold;float: left">图片库  <span class="hand" style="font-size: 16px;color:#FF9800;" @click="picture">More</span></div>
              <add-image-file-base-form :is-show="showAddImageFileBaseForm" :on-close="function (){showAddImageFileBaseForm = false;get_imgs_list();}"></add-image-file-base-form>
	            <div style=" width: 50%;float: left;">
		            <div style="line-height:75px;width: 60%;margin: auto">
			            <el-input placeholder="请输入内容" v-model="image_like" class="hand" clearable>
				            <div slot="append" @click="get_imgs_list(image_like)">搜索</div>
			            </el-input>
		            </div>
	            </div>
                <div style="float: right;margin: 0 40px 0 0;">
                    <el-button type="primary" size="medium" round @click="uploadpictures">上传图片</el-button>
                </div>
<!--	            <div style=" width: calc(25% - 20px);float: left;margin: 0 30px 0 0;">-->
<!--		            <el-button type="primary" size="small" round @click="uploadpictures">上传图片</el-button>-->
<!--		            <el-button type="success" size="small" round @click="mycollectionimg(7)">我的收藏</el-button>-->
<!--		            <el-button type="info" size="small" round @click="uploaded(7)">我的上传</el-button>-->
<!--		            <el-button type="warning" size="small" round @click="queryall(1)">全部图片</el-button>-->
<!--	            </div>-->
            </div>

            <div style="overflow:hidden;margin-left: 20px">
                <div v-for="(item,index) in imgs_list" :key="index"  style="width:352px;float: left;height: 356px;margin-left: 10px;margin-bottom: 20px;">
	                <div style="width: 96%;margin: 2%;height:100% ;background-color: white;border: 1px solid #E4E4E4;box-shadow: 0 0 8px 3px rgba(0,0,0,0.2);border-radius: 8px;overflow:hidden;" >
		                <div @click="content_info_type(item.id,item.payAmt,item.uuid,item.bgbType,item.PayStatus)" style="width: 100%; height: 65%;overflow: hidden;cursor: pointer;position: relative;">
                          <div v-if="item.uuid == user.uuid" style="position: relative;">
                            <img :src="item.cover" style="width:100%;height:229px;object-fit: cover">
                          </div>
                          <div v-if="item.payAmt > 0 && item.PayStatus == 0" style="position: absolute; top: 0; left: 0;z-index: 10;">
                              <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/pay2.png" style="width: 80px;height: 80px;">
                          </div>
                          <div v-if="item.uuid != user.uuid">
                            <img v-if="(item.payAmt > 0 && item.PayStatus == 1) || (item.payAmt == 0 && item.PayStatus == 0)" :src="item.cover" style="width:100%;height:229px;object-fit: cover">
                            <img v-if="item.payAmt > 0 && item.PayStatus == 0" :src="item.cover" style="width:100%;height:229px;object-fit: cover;filter: blur(5px);">
                          </div>
		                </div>
                    <div style="width: 100%;height: auto;">
                      <div style="width: 60%;height:8%;color: #1a1a1a;float:left;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;padding: 8px 2% 0px 2%;cursor: pointer;font-weight: bold">{{item.title}}</div>
                      <div >
                        <div v-show="item.payAmt > 0 && item.PayStatus == 0" style="font-size: 12px;line-height: 29px;color: red;">付费金额:{{item.payAmt}}(元)</div>
                      </div>
                    </div>

		                <div style="font-size: 14px;width:  96%;color: #6d737b;height:5%;margin-top: 5px;float:left;">
			                <div style="float: right;text-align:right;line-height: 20px;margin-left: 2px;">{{item.createTime}}</div>
			                <div style="float: left;line-height: 20px;"><span class="el-icon-view" style="font-size: 15px;color:#ff9800;padding-left:10px;"></span> {{item.clickCount}}</div>
		                </div>
		                <div style="width: 100%;height: calc(22% - 20px); border-top: 1px solid #CECFD0;margin-top: 10px;float:left;">
			                <div v-if=" typeof item.user == 'undefined'" >
				                <div style="width: 26px;height: 26px;float: left;margin: 15px 0 0 8px ">
					                <img :src="item.headIco" style="width: 100%;height: 100%;border-radius: 50%;">
				                </div>
				                <div style="font-size: 17px;color: #444444;float: left;margin: 16px 5px;font-weight: bold">{{ item.nickName }}</div>
			                </div>
			                <div v-else>
				                <div style="width: 26px;height: 26px;float: left;margin: 15px 0 0 8px ">
					                <img :src="item.user.headIco" style="width: 100%;height: 100%;border-radius: 50%;">
				                </div>
				                <div style="font-size: 17px;color: #444444;float: left;margin: 16px 5px;font-weight: bold">{{ item.user.nickName }}</div>
			                </div>
			                <div v-if="item.isCollection == 0" @click="addFavorite(item.id,item.bgbType)" style="float: right;margin-top: 18px;font-size: 20px;margin-right: 8px;font-weight: bold;color: #00ff00 " class="el-icon-star-off hand"></div>
			                <div v-else @click="cancelCollection(item.id,item.bgbType)" style="float: right;margin-top: 14px;font-size: 23px;margin-right: 8px;font-weight: bold;color:#00ff00" class="el-icon-star-on hand"></div>
		                </div>

	                </div>
                </div>
                <!--  空状态  -->
                <div v-if="imgs_list.length === 0">
                     <NoData></NoData>
                </div>
            </div>
            <el-dialog :close-on-click-modal="false" :visible.sync="Favorites">
                <div style="padding: 20px; display: flex">
                    <span style="font-size: 18px;font-weight: 600;margin-top: 8px">标签名： </span>
                    <!--  <el-input style="width: 400px" v-model="labelName"></el-input>-->
                    <el-autocomplete
                            style="width: 400px"
                            v-model="labelName"
                            :fetch-suggestions="querySearch"
                            placeholder="请输入内容"
                            @select="handleSelect">
                        <template slot-scope="{ item }">
                            <div class="name">{{ item.name }}</div>
                        </template>
                    </el-autocomplete>
                </div>
                <div style="display: flex; justify-content: end">
                    <el-button  @click="cancelLabel">取消</el-button>
                    <el-button  @click="addcollect">确定</el-button>
                </div>
            </el-dialog>
        </div>
        <div style="overflow:hidden;width: 1500px;height:auto; margin: 15px auto;background-color: #FFFFFF">
            <div style="width:100%;border-bottom: 1px solid #E4E4E4;overflow: hidden; height:75px;line-height:75px;">
              <div class="icon_list" style="float: left"></div>
              <div style=" width: calc(25% - 50px);font-size: 20px;color: #333333;font-weight:bold;float: left">视频库  <span class="hand" style="font-size: 16px;color:#FF9800;" @click="video">More</span></div>
	            <div style=" width: 50%;float: left;">
		            <div style="line-height:75px;width: 60%;margin: auto">
			            <el-input placeholder="请输入内容" v-model="video_like" class="hand" clearable>
				            <div slot="append" @click="get_videos_list(video_like)">搜索</div>
			            </el-input>
		            </div>
	            </div>
                <div style="float: right;margin: 0 40px 0 0;">
                    <el-button type="success" size="medium" round @click="electronics">上传视频</el-button>
                </div>
<!--	            <div style=" width: calc(25% - 20px);float: left;margin: 0 30px 0 0;">-->
<!--		            <el-button type="primary" size="small" round @click="electronics(0)">上传视频</el-button>-->
<!--		            <el-button type="success" size="small" round @click="mycollectionvideo(8)">我的收藏</el-button>-->
<!--		            <el-button type="info" size="small" round @click="uploaded(8)">我的上传</el-button>-->
<!--		            <el-button type="warning" size="small" round @click="queryall(2)">全部视频</el-button>-->
<!--	            </div>-->
            </div>
            <el-dialog :close-on-click-modal="false" width="482px" :visible.sync="uploadvideo" :show-close=false class="upload_att">
                <addUploadVideo ref="video_code" :on-close="function (){uploadvideo = false;get_videos_list();}"></addUploadVideo>
            </el-dialog>
            <div style="overflow:hidden;padding:0 20px;margin-bottom: 10px;">
                <div v-for="(item,index) in videos_list" :key="index" style="width:352px; float: left;height: 356px;margin-left: 10px;margin-bottom: 20px;">
                    <div style="width: 96%;margin: 2%;height:100% ;background-color: white;border: 1px solid #E4E4E4;box-shadow: 0 0 8px 3px rgba(0,0,0,0.2);border-radius: 8px;overflow:hidden;" >
                        <div @click="content_info_type(item.id,item.payAmt,item.uuid,item.bgbType,item.PayStatus)" style="width: 100%; height: 65%;overflow: hidden;cursor: pointer;position: relative;">
                          <div v-if="item.uuid == user.uuid">
                            <img  :src="item.cover" style="width:100%;height:229px;object-fit: cover;object-fit: cover">
                          </div>
                          <div v-if="item.payAmt > 0 && item.PayStatus == 0" style="position: absolute; top: 0; left: 0;z-index: 10;">
                              <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/pay2.png" style="width: 80px;height: 80px;">
                          </div>
                          <div v-if="item.uuid != user.uuid">
                            <img v-if="(item.payAmt > 0 && item.PayStatus == 1) || (item.payAmt == 0 && item.PayStatus == 0)" :src="item.cover" style="width:100%;height:229px;object-fit: cover">
                            <img v-if="item.payAmt > 0 && item.PayStatus == 0" :src="item.cover" style="width:100%;height:229px;object-fit: cover;filter: blur(5px);">
                          </div>
                          <div style="margin-top: -145px;margin-left:135px;position: absolute;"><img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon13.png" style="width: 64px;height: 64px"></div>
                        </div>
                      <div style="width: 100%;height: auto;">
                        <div style="width: 60%;height:8%;color: #1a1a1a;float:left;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;padding: 8px 2% 0px 2%;cursor: pointer;font-weight: bold;">{{item.title}}</div>
                        <div >
                          <div v-show="item.payAmt > 0 && item.PayStatus == 0" style="font-size: 12px;line-height: 29px;color: red;">付费金额:{{item.payAmt}}(元)</div>
                        </div>
                      </div>
	                    <div style="font-size: 14px;width:  96%;color: #6d737b;height:5%;margin-top:5px;float: left;">
		                    <div style="float: right;text-align:right;line-height: 20px;margin-left: 2px;">{{item.createTime}}</div>
		                    <div style="float: left;line-height: 20px;"><span class="el-icon-view" style="font-size: 15px;color:#ff9800;padding-left:10px;"></span> {{item.clickCount}}</div>
	                    </div>
	                    <div style="width: 100%;height: calc(22% - 20px); border-top: 1px solid #CECFD0;margin-top: 10px;float: left;">
		                    <div v-if=" typeof item.user == 'undefined'" >
			                    <div style="width: 26px;height: 26px;float: left;margin: 15px 0 0 8px ">
				                    <img :src="item.headIco" style="width: 100%;height: 100%;border-radius: 50%;">
			                    </div>
			                    <div style="font-size: 17px;color: #444444;float: left;margin: 16px 5px;font-weight: bold">{{ item.nickName }}</div>
		                    </div>
		                    <div v-else>
			                    <div style="width: 26px;height: 26px;float: left;margin: 15px 0 0 8px ">
				                    <img :src="item.user.headIco" style="width: 100%;height: 100%;border-radius: 50%;">
			                    </div>
			                    <div style="font-size: 17px;color: #444444;float: left;margin: 16px 5px;font-weight: bold">{{ item.user.nickName }}</div>
		                    </div>
		                    <div v-if="item.isCollection == 0" @click="addFavorite(item.id,item.bgbType)" style="float: right;margin-top: 18px;font-size: 20px;margin-right: 8px;font-weight: bold;color: #00ff00 " class="el-icon-star-off hand"></div>
		                    <div v-else @click="cancelCollection(item.id,item.bgbType)" style="float: right;margin-top: 14px;font-size: 23px;margin-right: 8px;font-weight: bold;color:#00ff00" class="el-icon-star-on hand"></div>
	                    </div>

                    </div>
                </div>
                <!--  空状态  -->
                <div v-if="videos_list.length === 0">
                    <NoData></NoData>
                </div>
            </div>
        </div>
        <div style="width: 1500px;height:auto; margin: 15px auto;background-color:#FFFFFF">
            <div style="width:100%;border-bottom: 1px solid #E4E4E4;overflow: hidden; height:75px;line-height:75px;">
                <div class="icon_list" style="float: left"></div>
                <div style=" width: calc(25% - 50px);float: left;font-size: 20px;color: #333333;font-weight:bold;">素材库  <span class="hand" style="font-size: 16px;color:#FF9800;" @click="file">More</span></div>
	            <div style=" width: 50%;float: left;">
		            <div style="line-height:75px;width: 60%;margin: auto">
			            <el-input placeholder="请输入内容" v-model="enclosure_like" class="hand" clearable>
				            <div slot="append" @click="get_enclosure_list(enclosure_like)">搜索</div>
			            </el-input>
		            </div>
	            </div>
                <div style="float: right;margin: 0 40px 0 0;">
                    <el-button type="warning" size="medium" round @click="attachment">上传附件</el-button>
                </div>
<!--	            <div style=" width: calc(25% - 20px);float: left;margin: 0 30px 0 0;">-->
<!--		            <el-button type="primary" size="small" round @click="attachment(0)">上传附件</el-button>-->
<!--		            <el-button type="success" size="small" round @click="mycollectionenclosure(9)">我的收藏</el-button>-->
<!--		            <el-button type="info" size="small" round @click="uploaded(9)">我的上传</el-button>-->
<!--		            <el-button type="warning" size="small" round @click="queryall(3)">全部附件</el-button>-->
<!--	            </div>-->
            </div>
            <el-dialog :visible.sync="uploadAttachment" width="705px" :close-on-click-modal="false" class="upload_att">
                <addUploadAttachment ref="chment" :info=uploadAttachmentInfo></addUploadAttachment>
            </el-dialog>
            <!--  附件展示   -->
            <div id="enclosure_list" v-if="enclosure_list.length>0">
                <div class="col" v-for="number in [0,1,2,3]">
                    <div class="folder" v-for="(item,index) in enclosure_list"  :key="index"   v-if="index%4===number" >
	                    <el-popover trigger="hover" placement="bottom"  width="325" >
		                    <div style="width: 100%;height: auto;text-align: center;font-size: 18px;font-weight: bold">{{item.title}}</div>
		                    <div class="fileList">
                                <div class="rolling">
                                    <div class="file hand" v-for="(i,idx) in item.enclosure" :key="idx" style="height:100%; cursor: pointer;background: #e3d8d8;margin-bottom: 8px; overflow: hidden;border-radius: 3px;">
                                        <div style="float: left;width: calc(100% - 50px);line-height: 40px;">
                                            <div class="el-icon-paperclip" style="float: left;font-size: 16px;margin:0 3px;line-height: 40px;color: #67c23a"></div>
                                            <el-tooltip effect="light" :content="i.name" placement="top" style="float: left;">
                                                <div style="width: 90%;overflow: hidden;text-overflow :ellipsis;white-space: nowrap;height: 40px;line-height: 40px;">{{i['name']}}</div>
                                            </el-tooltip>
                                        </div>
                                        <el-button  @click="download(i['url'],i['name'],i['hash'],item.id,item.PayStatus,item.payAmt)" :disabled="isDownloading" style="border: none;background: #e3d8d8;width: 18px;height: 18px;"><i style="font-size: 18px;" class="el-icon-upload"></i></el-button>
                                    </div>
                                </div>
		                    </div>
	                        <div class="folderImgAndName" slot="reference">
		                        <div class="folderImg" >
			                        <i class="el-icon-folder-add"  style="font-size: 25px;color: #f8ae00"></i>
	<!--		                        <i v-else class="el-icon-folder-remove"  style="font-size: 25px;color: #f8ae00"></i>-->
		                        </div>
		                        <div class="folder_img_url" v-if="item.isMe==0">
			                        <el-tag type="success" v-if="item.PayStatus == 1 && item.paymentType == 2 " size="mini" effect="dark">已购</el-tag >
			                        <el-tag type="danger" v-else-if="item.PayStatus == 0 && item.paymentType == 2 " size="mini" effect="dark">付费</el-tag >
			                        <el-tag  v-else-if="item.PayStatus == 0 && item.paymentType == 0"  size="mini" effect="dark">免费</el-tag>

		                        </div>
		                        <div class="folder_img_url" v-if="item.isMe==1">
			                        <el-tag type="info"  size="mini" effect="dark">我的</el-tag >
		                        </div>

		                        <div class="folderName hand">{{item.title}}</div>
	                            <!-- 收藏  -->
		                        <div v-if="item.isCollection == 0" @click="addFavorite(item.id,item.bgbType)" style="float: right;margin-top: 10px;font-size: 20px;margin-right: 5px;font-weight: bold;color: #00ff00 " class="el-icon-star-off hand"></div>
		                        <div v-else @click="cancelCollection(item.id,item.bgbType)" style="float: right;margin-top: 8px;font-size: 23px;margin-right: 5px;font-weight: bold;color:#00ff00" class="el-icon-star-on hand"></div>


	                            <label v-if="is_login == 1 && item.uuid === user.uuid">
	                                <!--  删除  -->
	                                <div style="float: right;margin-top: 10px;font-size: 18px;margin-right: 5px;font-weight: bold;color: red" class="el-icon-delete hand" @click="deleteEnclosure(item)"></div>
	                                <!--  编辑  -->
	                                <div style="float: right;margin-top: 10px;font-size: 18px;margin-right: 5px;font-weight: bold;color: #1C7EFF" class="el-icon-edit  hand" @click="attachment(1,item)"></div>
	                            </label>
	                        </div>
	                    </el-popover>
                    </div>
                </div>
            </div>
            <!--  空状态  -->
            <div v-if="enclosure_list.length === 0">
                <NoData></NoData>
            </div>
        </div>
        <Bottom></Bottom>
    </div>
</template>
<script>
    import Bottom from "../../components/common/Bottom";
    import addImageFileBaseForm from "@/components/common/addImageFileBaseForm.vue";
    import addUploadAttachment from '../../components/common/addUploadAttachment.vue';
    import addUploadVideo from "../../components/common/addUploadVideo.vue";
    import NoData from "../../components/common/NoData";
    export default{
        components: {
            Bottom,
            addImageFileBaseForm,
            addUploadAttachment,
            addUploadVideo,
            NoData,
        },
        data() {
            return {
	            uploadAttachmentInfo:{
					id:0,
		            auth:1,
		            material_name:"",
		            files:[],
		            types:"",
		            paytoread:false,
		            input_paytoread:""
	            },
              enclosure_list:[],
              showAddImageFileBaseForm:false,
              plate_index:0,
	            enclosure_like:'',
	            video_like:'',
	            image_like:'',
              //全局变量
              type_list:[],
              like:'',
              standard_list:[],
              rule_list:[],
              imgs_list:[],
              videos_list:[],
              activeName: 'first',
              activeNamecol:'firsta',
              plate_indexcol:1,
              likecol:'',
              avtivecolor: "color:#FFFFFF",
              avtivecolorcol:"color:#FFFFFF",
              avtivecolorcoltype:"color:#FFFFFF",
              likenane:'',
              case_list:[],
              content_list:[],
              id:'',
              EnclosureInfo:[],
              realNameStatus:0,
              stash_type:[
                  {name:'标准规范',typeId:1},
                  {name:'政策法规',typeId:2},
                  {name:'项目案例',typeId:3},
                  {name:'文章专著',typeId:4},
                  {name:'图片',typeId:5},
                  {name:'视频',typeId:6},
                  {name:'素材',typeId:7},
              ],
              low:1,
              //  附件
              uploadAttachment:false,
              // 视频
              uploadvideo:false,
              knowledgeinfo:{},
              //   收藏
              Favorites:false,
              labelName:"",
              restaurants:[],
              clickedId:0,
              blurredImageUrl: 'https://oss.baigongbao.com/img/zsff.jpeg',
              clickBgbType:0,
              kwId:0,
              isDownloading: false,
            }
        },

        computed: {
            //加载后马上执行
            user(){
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },

        },
        created() {
            var that = this;
            //预支付订单监听
            this.$EventBus.$EventBus.$off("prePayOrder");
            this.$EventBus.$on("prePayOrder", (data) => {
                this.$nextTick(() => {
                    if (data.code == 100) {
                        that.bankPay(data.toPay);
                    }
                });
            });
        },
        watch: {
            is_login(val) {
                if (val){
                    var that = this;
                    that.get_type_list();
                    that.get_standard_list();
                    that.get_shop_case_list();
                    that.get_content_list();
                    that.get_videos_list();
                    that.get_rule_list();
                    that.get_imgs_list();
                    that.get_enclosure_list();
                }
            }
        },
        mounted:function(){
            var that = this;
            //监听支付成功
            this.$EventBus.$EventBus.$off("payMsg");
            this.$EventBus.$on("payMsg", (data) => {
                this.$nextTick(() => {
                    if (data.code == 100) {
                        that.get_enclosure_list();
                    }
                });
            });

            that.get_type_list();
            that.get_standard_list();
            that.get_shop_case_list();
            that.get_content_list();
            that.get_videos_list();
            that.get_rule_list();
            that.get_imgs_list();
            that.get_enclosure_list();

        },

        methods:{
            //立即支付
            bankPay(data){
                var that = this;
                //判断是对公还是公绑私
                if(data.activeName != 'corporate'){
                    var params = {};
                    params.orderNo = data.orderNo;
                    params.code = data.code;
                    params.payPwd = data.payPwd;
                    that.newApi.settleAccountTransferDetermine(params).then(() => {});
                }
            },
            // 搜索资料
            searchProfile() {
                if(this.like == ""){
                    this.utils.err('搜索内容不能为空')
                    return
                }else {
                    this.$router.push({path: '/filebase/fileinfo/0', query: {content: this.like, type: this.low}})
                }
            },

            async getUserCollectionLabelList() {
                try {
                    const response = await this.newApi.getUserCollectionLabelList({});
                    if (response.isSuccess === 1) {
                        this.restaurants = response.data;
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            async querySearch(queryString, cb) {
                if (this.restaurants.length === 0) {
                    // 如果填写的数据为空，先加载数据
                    await this.getUserCollectionLabelList();
                }

                // 进行搜索
                const results = queryString
                    ? this.restaurants.filter(restaurant =>
                        restaurant.name.toLowerCase().includes(queryString.toLowerCase())
                    )
                    : this.restaurants;

                // 调用 callback 返回建议列表的数据
                cb(results);
            },
            handleSelect(item) {
                this.labelName = item.name; // 将选择的名称赋值给 labelName
                this.selectedRestaurant = item;
            },
            // 我上传的图片
            uploaded(bgbType){
                var that = this;
                that.newApi.getKwMyUpload({
                    bgbType:bgbType,
                    page: 1,
                    pageSize: 20,
                }).then((res)=>{
                    if (res.isSuccess == 1) {
                        if (bgbType == 7){
                            that.imgs_list = res.data;
                        }else if(bgbType == 8){
                            that.videos_list = res.data
                        }else if(bgbType == 9){
                            that.enclosure_list = res.data;
                        }
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            // 我的收藏图片
            mycollectionimg(type){
                var that = this;
                that.newApi.getKwMyCollection({
                    bgbType:type,
                    page: 1,
                    pageSize: 20,
                }).then((res)=>{
                    if (res.isSuccess == 1) {
                        that.imgs_list = res.data;
                     }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            // 我的收藏视频
            mycollectionvideo(type){
                var that = this;
                that.newApi.getKwMyCollection({
                    bgbType:type,
                    page: 1,
                    pageSize: 20,
                }).then((res)=>{
                    if (res.isSuccess == 1) {
                        that.videos_list = res.data;
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            // 我收藏的附件
            mycollectionenclosure(type){
                var that = this;
                that.newApi.getKwMyCollection({
                    bgbType:type,
                    page: 1,
                    pageSize: 20,
                }).then((res)=>{
                    if (res.isSuccess == 1) {
                        that.enclosure_list = res.data;
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            addFavorite(id,bgbtype) {
                if(this.is_login === 0){
                    this.$store.commit('setShowLoginPopup', true);
                    return
                }
                this.Favorites = true;
                this.selectedItemId = id;
                this.selectedbgbtype = bgbtype
                this.getUserCollectionLabelList()
            },
            // 收藏
            addcollect(){
                this.newApi.addCollection({
                    bgbType: this.selectedbgbtype,
                    label: this.labelName,
                    id: this.selectedItemId
                }).then(res => {
                    if (res.isSuccess == 1) {
                        this.labelName = '';
                        this.Favorites = false;
                        this.utils.sus(res.data);
                        if (this.selectedbgbtype == 7 ){
                            // 请求图片
                            this.get_imgs_list();
                        }else if (this.selectedbgbtype == 8){
                            // 请求视频
                            this.get_videos_list();
                        }else if(this.selectedbgbtype == 9){
                            // 请求附件
                            this.get_enclosure_list();
                        }

                    }
                })
            },
            // 取消标签
            cancelLabel() {
                this.labelName = '';
                this.Favorites = false;
            },
            download(url,name,hash,id,PayStatus,payAmt){
                var that = this;
              if (!this.utils.toAuth(1)) {
                return false
              }
                    if (PayStatus == 0 && payAmt > 0){
                        this.payKnowledge(id)
                    }else {
                        this.isDownloading = true;  //禁用按钮
                        this.utils.download(url,name,hash)
                        setInterval(() => {
                            this.isDownloading = false;
                        }, 5000)
                    }
            },
            payKnowledge(id){
                var that = this;
                that.newApi.payKnowledge({
                  knowledgeId:id,
                  paidUuid:that.user.uuid,
                }).then((res)=>{
                    if (res.isSuccess == 1) {
                        // <AaDaPayCode ref="aDaPayCode" type="2" payType="14"  :memberId="paidKnowledgeinfo.memberId"  :payAmt="paidKnowledgeinfo.payAmt" :collectionUuid="paidKnowledgeinfo.addUuid" :orderNo="orderNo" @change="changeOrder($event)"></AaDaPayCode>
                        let params = that.$store.state.payParams;
                        params.type = 2;
                        params.payType = 14;
                        params.payAmt = res.data.payAmt;
                        params.collectionUuid = res.data.addUuid;
                        params.memberId = res.data.memberId;
                        params.orderNo = res.data.orderNo;
                        that.sign = that.utils.random_string(6);
                        that.newApi.addAndSavePrePayOrder({type:1,payParams:JSON.stringify(params),sign:that.sign}).then(() => {
                            that.b('/newAdaPay/?sign='+that.sign);
                        })
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            electronics(){
                var that = this;
              if (!this.utils.toAuth(1)) {
                return false
              }
                    this.uploadvideo = true


            },
	        deleteEnclosure(item){
              var that = this;
              this.utils.confirm("确定删除吗？", () => {
                that.newApi.delKnowledge({
                  id:item.id,

                }).then((res)=>{
                  if (res.isSuccess == 1) {
                    that.utils.sus(res.data);
                    that.get_enclosure_list();
                  }
                }).catch((err)=>{
                  console.log(err)
                })
              })
            },
            attachment(type,item){
                var that = this;
              if (!this.utils.toAuth(1)) {
                return false
              }
                    if (type==1){
                      if (item.paymentType != 0){
                        //消息提示
                        this.utils.confirm("付费素材不可编辑");return false;
                      }
                      that.uploadAttachmentInfo.auth = item.auth;
                      that.uploadAttachmentInfo.files = item.enclosure;
                      that.uploadAttachmentInfo.material_name = item.title;
                      that.uploadAttachmentInfo.types = JSON.parse(item.kwTypeArr);
                      that.uploadAttachmentInfo.id = item.id;
                    }
                    this.uploadAttachment  = true;

            },
            addMore(id){
                this.$router.push("/filebase/more/"+id)
            },
            picture(){
                this.utils.a("/filebase/more/7")
            },
            video(){
                this.$router.push("/filebase/more/8")
            },
            file(){
                this.$router.push("/filebase/more/9")
            },

            //方法
            look_type(fid) {
                this.$router.push('/filebase/fileinfo/'+fid);
            },
            handleCommand(command) {
                this.$router.push('/filebase/fileinfo/'+command);
                // this.$message(command);
            },
            handleClick:function (id){
                var that = this;
              if (!this.utils.toAuth(1)) {
                return false
              }
                    that.utils.a('/filebase/upload/' + id);

            },

            get_standard_list:function (){
                var that = this;
                this.newApi.getFilebaseList({
                    page: 1,
                    pageSize:20 ,
                    type:1,
                    bgbType:6,
                }).then((res)=>{
                    that.standard_list = res.data;
                }).catch((err)=>{
                    console.log(err)
                })
            },
            get_rule_list:function (){
                var that = this;
                this.newApi.getFilebaseList({
                    page: 1,
                    pageSize: 20,
                    type:2,
                    bgbType:6,
                }).then((res)=>{
                    that.rule_list = res.data;
                }).catch((err)=>{
                    console.log(err)
                })
            },
            get_shop_case_list:function (){
                var that = this;
                this.newApi.getFilebaseList({
                    page: 1,
                    pageSize: 20,
                    type:3,
                    bgbType:6,
                }).then((res)=>{
                    that.case_list = res.data;
                }).catch((err)=>{
                    console.log(err)
                })
            },

            get_content_list:function (){
                var that = this;
                this.newApi.getFilebaseList({
                    page: 1,
                    pageSize: 20,
                    type:4,
                    bgbType:6,
                }).then((res)=>{
                    that.content_list = res.data;
                }).catch((err)=>{
                    console.log(err)
                })
            },
            get_imgs_list:function (like = ""){
                var that = this;
                this.newApi.getFilebaseList({
                    page: 1,
                    pageSize: 20,
                    bgbType:7,
	                like:like
                }).then((res)=>{
                    that.imgs_list = res.data;
                }).catch((err)=>{
                    console.log(err)
                })
            },
            get_videos_list:function (like = ""){
                var that = this;
                this.newApi.getFilebaseList({
                    page: 1,
                    pageSize: 20,
                    bgbType:8,
	                like:like
                }).then((res)=>{
                    that.videos_list = res.data;
                }).catch((err)=>{
                    console.log(err)
                })
            },
          get_enclosure_list:function (like = ""){
                var that = this;
                this.newApi.getFilebaseList({
	                page: 1,
	                pageSize: 20,
                    bgbType:9,
	                like:like
                }).then((res)=>{
                    that.enclosure_list = res.data;
                }).catch((err)=>{
                    console.log(err)
                })
            },

            // file_info(fid){
            //     this.a('/filebase/fileinfo/'+fid);
            // },

            get_type_list(){
                var that = this;
                this.newApi.getTypeList({
                    lv:3,
                }).then((res)=>{
                    that.type_list = res.data;
                }).catch((err)=>{
                    console.log(err)
                })
            },
            // 文章
            content_info:function(id){
                var that = this;
              if (!this.utils.toAuth(1)) {
                return false
              }
                    that.utils.a('/content/info/'+id+"/2");
            },
            // 图片视频详情
            content_info_type:function(id,payAmt,uuid,bgbType,PayStatus){
                var that = this;
                that.kwId = id;
                that.clickBgbType = bgbType;
              if (!this.utils.toAuth(1)) {
                return false
              }
                    that.utils.a('/content/picture/'+id);
            },

            plate_movecol(index){
                this.plate_indexcol = index;
            },

            plate_leavecol(){
                this.plate_indexcol = 0;
            },
            Mouseover() {
                this.avtivecolor = "color:red"; // 进入 悬停区域,更改文字颜色
            },

            // 鼠标"离开"触发此方法
            Mouseleave () {
                this.avtivecolor = "color:#FFFFFF"; // 离开 悬停区域,文字颜色复原
            },

            Mouseovercol() {
                this.avtivecolorcol = "color:red";
            },

            Mouseleavecol () {
                this.avtivecolorcol = "color:#FFFFFF";
            },


            plate_move(index){
                this.plate_index = index;
            },

            plate_leave(){
                this.plate_index = 0;
            },
            add_type(typeId){
                this.low = typeId;
            },
            // 发布完附件 子组件执行完回调方法
            complete(){
                this.uploadAttachment = false;
                this.get_enclosure_list()
	              this.uploadAttachmentInfo.id =0;
	              this.uploadAttachmentInfo.auth =1;
                this.uploadAttachmentInfo.files = [];
                this.uploadAttachmentInfo.material_name = "";
                this.uploadAttachmentInfo.paytoread = false;
                this.uploadAttachmentInfo.input_paytoread = "";
                this.uploadAttachmentInfo.types = "";
            },
            contentvideo (){
                this.uploadvideo = false
                this.get_videos_list()
                this.$refs.video_code.videos = [];
                this.$refs.video_code.inputvideo = "";
                this.$refs.video_code.types = "";
            },
            // 查询全部
            queryall(typeList){
                if (typeList == 1){
	                this.image_like = '';
                    this.get_imgs_list("")
                }else if( typeList  == 2){
	                this.video_like = '';
                    this.get_videos_list("")
                }else if(typeList == 3){
					          this.enclosure_like = '';
                    this.get_enclosure_list("")
                }

            },
            uploadpictures(){
                var that = this;
              if (!this.utils.toAuth(1)) {
                return false
              }
                    that.showAddImageFileBaseForm = true
            },
            // 取消收藏
            cancelCollection(id,bgbType){
                this.newApi.cancelCollection({
                    id: id,
                    bgbType: bgbType,
                }).then(res =>{
                    if (res.isSuccess == 1) {
                        this.utils.sus(res.data)
                        if (bgbType == 7 ){
                            // 请求图片
                            this.get_imgs_list();
                        }else if (bgbType == 8){
                            // 请求视频
                            this.get_videos_list();
                        }else if(bgbType == 9){
                            // 请求附件
                            this.get_enclosure_list();
                        }
                    }
                })
            }
        },

    }
</script>
<style lang="scss" scoped>

    #enclosure_list{
        margin-bottom: 10px;height: auto;display: flex; gap:11px;
        padding:20px 30px; background: #FFF;
	    overflow: hidden;
        .col{
            flex:1;
            .folder{
                width: 100%;
                margin-bottom: 10px;
	            padding : 10px 0;
                background: #f4f4f4;
                box-shadow: 0 0 8px 3px rgba(0,0,0,0.2);
	            border-radius: 8px;
                .folderImgAndName{
                    overflow: auto;
                    height: 40px;
                    margin-left: 5px;
                    .folderImg{
                        width: 26px;height: 24px; margin: 7.5px;
                        float: left;
                    }
                    .folder_img_url{
                        width: 32px;
                        float: left;
	                    line-height: 40px;
	                    margin-right: 10px;
                    }
                    .folderName{
                        float: left;color: #555555;font-size:17px;text-overflow :ellipsis;overflow: hidden;
                        white-space :nowrap;margin-left: 2.5px;font-weight: bold;
                        height:40px;line-height: 40px;
                        width: 160px;
                    }
                }
                .folder_border {
                    float: left;
                    width: 100%;
                    border-top: 1px solid #CECFD0;
                }
                .folder_more {
                    clear: both;
                    text-align: center;
	                padding-top: 10px;;
                    font-size: 14px;
                    cursor: pointer
                }
                .fileList{
                    width: 330px;
                    margin: 0 auto;
	                height: 40px;
                    .file{
                        cursor: pointer;background: #e3d8d8;margin-bottom: 8px; overflow: hidden;border-radius: 3px;
                        img{
                            width:22px;height:26px;float: left;
                            margin: 11px 10px 11px 16px;
                        }
                        span{
                            height:48px;line-height:48px; width:245px;color:#000;float: left; text-overflow :ellipsis;white-space :nowrap;overflow : hidden;
                        }
                        i {
                            float: left;
                        }
                    }

                }

            }
        }
    }
    .zhishi {
        margin-top:-40px;
    }

    .title{
        overflow: hidden;
        overflow-y: scroll;
        max-height: 300px;
        line-height: 26px;
    }
    .title::-webkit-scrollbar {
        display: none;
    }

    .Fcti {
        width: 100%;
        background-color: #c2c2c266;
        border-radius: 5px 5px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: 40px;
    }
    .icon_list {
	    width: 0;
	    height: 0;
	    border-top-left-radius: 8px;
	    border-bottom: 20px solid transparent;
	    border-left: 20px solid #031f88;
	    border-right: 20px solid transparent;
	    border-top: 20px solid #031f88;
    }
    .button {
        width: 120px;
        height: 38px;
        background:#F1F1F1;
        border-radius: 20px;
        display: flex;
        justify-content: center;
    }
    .bottom_name {
        width: 72px;
        height: 21px;
        font-size: 18px;
        color: #444444;
    }
    .rolling {
        max-height:500px;
        overflow-y: scroll;
    }
    .rolling::-webkit-scrollbar {
        display: none;
    }

</style>
<style scoped>
    /deep/ .el-tabs__item {
        font-size: 16px;
    }
    /deep/ .font-weight {
        font-size: 17px;
    }

    /deep/ .el-dropdown {
        font-size:17px
    }
    /deep/ .wrapper-icon {
        color:#f1f1f1;
    }
    /deep/ .icon {
        color:#f1f1f1;
    }

</style>

<style>
    .upload_att .el-dialog__body {
        padding: 28px 2px !important;
    }
    .upload_att .el-dialog__header {
        padding: 0px !important;
    }
</style>
